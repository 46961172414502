<template>
  <div>
    <header class="bg-white shadow-sm lg:static lg:overflow-y-visible">
        <div class="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
          <div class="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
            <div class="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
              <div class="flex flex-shrink-0 items-center">
                <router-link to="/">
                  <img class="h-8 w-auto" src="/oasis.png" alt="OASIS">
                </router-link>
              </div>
            </div>
            <div class="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-7">
              <div class="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                <div class="w-full">
                  <label for="search" class="sr-only">Search</label>
                  <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <input id="search" name="search" class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Search" type="search" v-model="searchTerm" @input="searchTranscript">
                  </div>
                </div>
              </div>
            </div>
            <div class="min-w-0 flex md:px-8 lg:px-0 xl:col-span-3 justify-right items-center">

            <div class=" flex items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2 hover:opacity-70 cursor-pointer">
              <input
                id="file-upload"
                name="file-upload"
                class="sr-only"
                type="file"
                ref="audioInput"
                @change="handleAudioChange"
                accept="audio/*"
              />
              <div class="mt- flex text-sm leading-6 text-gray-600" id="upload-text" v-if="!selectedFile">
                <label for="file-upload" class="relative cursor-pointer rounded-md bg-white focus-within:outline-none">
                  <i class="fa fa-file pink-text mr-1"></i>
                  <span class="font-semibold pink-text">Upload audio</span>
                  <!-- <span class="hidden lg:inline-block"> or drag</span> -->
                </label>
              </div>
              <div class="mt- flex text-sm leading-6 text-gray-600 font-bold cursor-pointer" v-else>
                <label for="file-upload" class="cursor-pointer">
                  <i class="mr-3 animate-spin fa fa-spinner text-gray-300" v-if="loadingTranscript"></i>
                  <i class="fa fa-file pink-text mr-1"></i> {{ selectedFileTruncated }}
                </label>
              </div>
            </div>

            <!-- EXPORT -->
            <button @click="exportFile" type="button" style="height: 36px" class="ml-4 flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <i class="fa fa-file-arrow-down text-white"></i>
              Export
            </button>

            <!-- UNDO -->
            <div class="relative ml-4">
              <button @click="undo" class="hover:opacity-70">
                <i class="fa fa-undo text-2xl text-gray-400"></i>
              </button>
            </div>

            <!-- Profile dropdown -->
            <div class="relative ml-4">
              <div>
                <button @click="settingsMenuOpened = !settingsMenuOpened" type="button" class="relative flex rounded-full text-sm hover:opacity-70" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                  <span class="absolute -inset-1.5"></span>
                  <span class="sr-only">Open settings menu</span>
                  <i class="fa fa-sliders text-2xl text-gray-400"></i>
                </button>
              </div>
              <div v-if="settingsMenuOpened" style="width: 28rem" class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                <h2 class="px-4 py-2 text-base font-semibold leading-7 text-gray-900"><!--<i class="fa fa-gear"></i> -->Settings</h2>

                <div class="block px-4 py-2 text-sm text-gray-700 relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input v-model="smartFormatting" id="smart_formatting" aria-describedby="comments-description" name="comments" type="checkbox" class="cursor-pointer h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                  </div>
                  <div class="ml-3 w-full" role="menuitem">
                    <label for="smart_formatting" class="block  cursor-pointer w-full font-medium text-gray-900">Smart Formatting
                      <span id="comments-description" class="text-gray-500 font-normal">Format dates, numbers & more.</span>
                    </label>
                  </div>
                </div>

                <div class="block px-4 py-2 text-sm text-gray-700 relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input v-model="punctuation" id="punctuation" aria-describedby="comments-description" name="comments" type="checkbox" class="cursor-pointer h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                  </div>
                  <div class="ml-3 w-full" role="menuitem">
                    <label for="punctuation" class="block  cursor-pointer w-full font-medium text-gray-900">Punctuation
                      <span id="comments-description" class="text-gray-500 font-normal">Include punctuation & capitalization.</span>
                    </label>
                  </div>
                </div>

                <div class="block px-4 py-2 text-sm text-gray-700 relative flex items-start">
                  <label for="name" class="w-56 inline-block bg-white px-1 text-sm font-medium text-gray-900">Seconds of silence between segments:</label>
                  <input v-model="segmentSilence" type="text" name="name" id="name" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.0">
                </div>

                <div class="block px-4 py-2 text-sm text-gray-700 relative flex items-center">
                  <label for="location" class="w-56 inline-block px-1 text-sm font-medium text-gray-900">Model:</label>
                  <select v-model="model" id="location" name="location" class="ml-1 block w-full rounded-md border-0 py-1.5 pl-1 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option value="whisper-medium">Whisper Cloud</option>
                    <option value="nova-2-ea">Nova-2-ea</option>
                    <option value="nova">Nova</option>
                    <option value="enhanced">Enhanced</option>
                    <option value="base">Base</option>
                  </select>
                </div>

              </div>
            </div>

          </div>
          </div>
        </div>
      </header>

    <div class="py-4 px-4 sm:px-6 lg:px-8">
        <div class="text-sm text-right absolute right-8 text-gray-700 opacity-70">
          <div v-if="utterances && utterances.length > 0">
            <b>Segments: </b> {{ utterances.length }}<br />
            <b>Words: </b> {{ totalWords }}<br />
            <b>Approved: </b> {{ totalApprovalProgress.toFixed(1) }}%
          </div>
          <div v-if="hoveredSegment">
            <hr class="my-2" />
            <b>Segment: </b>{{ getSegmentNumber(hoveredSegment) }}<br />
            <b>Confidence: </b><span>{{ hoveredSegment.confidence }}</span><br />
            <b>Start: </b>{{ convertToReadableTime(hoveredSegment.start) }}<br />
            <b>Stop: </b>{{ convertToReadableTime(hoveredSegment.end) }}<br />
            <b>Word Count: </b>{{ hoveredSegment.words.length }}<br />
            <b>Approved: </b>{{ hoveredSegment.approved }}
          </div>
          <div v-if="hoveredWord">
            <hr class="my-2" />
            <b>Word: </b>{{ hoveredWord.punctuated_word }}<br />
            <b>Confidence: </b><span>{{ hoveredWord.confidence }}</span><br />
            <b>Start: </b>{{ convertToReadableTime(hoveredWord.start) }}<br />
            <b>Stop: </b>{{ convertToReadableTime(hoveredWord.end) }}
          </div>
        </div>

        <div class="text-xs text-left absolute bottom-20 right-0 text-gray-700 opacity-30 hover:opacity-70">
          <div class="col-span-1 font-bold mb-2">Keyboard Shortcuts:</div>
          <div class="grid grid-cols-2 gap-4">
            <div><div class="kbd">CTRL</div> + <div class="kbd">[</div></div>
            <div class="col-span-">Previous</div>
            <div class="col-span-1"><div class="kbd">CTRL</div> + <div class="kbd">]</div></div>
            <div class="col-span-">Next</div>
            <div class="col-span-1"><div class="kbd">CTRL</div> + <div class="kbd">A</div></div>
            <div class="col-span-">Approve</div>
            <div class="col-span-1"><div class="kbd">CTRL</div> + <div class="kbd">R</div></div>
            <div class="col-span-">Reject</div>
            <div class="col-span-1"><div class="kbd">CTRL</div> + <div class="kbd">P</div></div>
            <div class="col-span-">Loop</div>
          </div>
        </div>
      <!-- <button
        @click="transcribeAudio"
        class="button-pink p-1 px-4 hover:opacity-50 rounded-lg"
      >
        Transcribe
      </button> -->
      <div
        class="transcription text-lg max-w-6xl mx-auto"
        ref="transcription"
      >
        <span
          v-for="(utterance, index) in utterances"
          :key="utterance.id"
          @mouseenter="showSegmentOptions(utterance)"
          @mouseleave="clearSegment"
          class="flex my-1 py-3 pl-3 rounded-sm"
          :class="{ 'bg-gray-100': utterance.approved === false, 'bg-green-100': utterance.approved === true, 'pulse-bg': segmentsProcessing.has(utterance.id), 'bg-red-500/40': erroredSegments.has(utterance.id) }"
          style="min-height: 4.4rem;"
        >
          <div class="left-timestamp text-gray-400 float-left mr-12 w-1/5">
            <div class="flex items-center">
              <span :class="{ 'pink-text': selectedSegmentIndex === index }">{{ convertToReadableTime(utterance.start) }}</span>
              <i class="ml-3 animate-spin fa fa-spinner text-gray-300" v-if="segmentsProcessing.has(utterance.id)"></i>
              <i class="ml-3 fa fa-rotate text-red-600 cursor-pointer hover:opacity-70" @click="triggerForceAlignAndSave(utterance.id, index)" title="Retry force align" v-else-if="erroredSegments.has(utterance.id)"></i>
            </div>
            <div v-if="hoveredSegment == utterance" class="actions flex text-xs text-gray-900">
              <button class="kbd mr-1 hover:opacity-70" @click="addNewUtteranceAfter(index)" title="Add Segment Below"><i class="fa fa-plus"></i></button>
              <button v-if="utterance.words && utterance.words.length > 0" class="kbd mr-1 hover:opacity-70" @click="splitUtteranceAtCursor(index)" title="Split Segment at Cursor"><i class="fa fa-i-cursor"></i></button>
              <button v-else class="kbd mr-1 hover:opacity-70" @click="deleteUtterance(index)" title="Delete empty segment"><i class="fa fa-trash"></i></button>
              <button @click="openEditSegmentModal(utterance, index)" class="kbd mr-1 hover:opacity-70" title="Edit Segment Times">
                <i class="fa fa-edit"></i>
              </button>
              <button 
                :disabled="segmentsProcessing.has(utterance.id) || (utterances[index+1] && segmentsProcessing.has(utterances[index+1].id))" 
                :class="segmentsProcessing.has(utterance.id) || (utterances[index+1] && segmentsProcessing.has(utterances[index+1].id)) ? 'opacity-20' : 'hover:opacity-70'"
                class="kbd mr-1" @click="handleMovementUp(index, $event)" title="Move word up into Segment"><i class="fa fa-arrow-up"></i>
              </button>
              <button 
                :disabled="segmentsProcessing.has(utterance.id) || (utterances[index+1] && segmentsProcessing.has(utterances[index+1].id))" 
                :class="segmentsProcessing.has(utterance.id) || (utterances[index+1] && segmentsProcessing.has(utterances[index+1].id)) ? 'opacity-20' : 'hover:opacity-70'"
                class="kbd mr-1" @click="handleMovementDown(index, $event)" title="Move word down out of Segment"><i class="fa fa-arrow-down"></i>
              </button>
            </div>
            <div v-else class="text-xs">
              <span :class="{ 'text-red-500 font-bold': calculateSegmentDuration(utterance.start, utterance.end) > 30 }">({{ calculateSegmentDuration(utterance.start, utterance.end).toFixed(1) }} sec)</span>
            </div>
          </div>
          <div class="w-3/5 outline-none" :ref="`utteranceText-${utterance.id}`" @click="selectedSegmentIndex = index" :contenteditable="utterance.approved === null && !segmentsProcessing.has(utterance.id) ? true : false" @blur="forceAlignAndSave($event, index)" @keyup="storeCursorPosition(index)" style="outline: none; overflow-wrap: break-word;"
          :key="utterance.words.map(word => word.start).join('-')">
            <span
              v-for="(word, i) in utterance.words"
              :key="`${utterance.id}-${i}-${word.start}`"
              @mouseenter="showStats(word)"
              @mouseleave="clearStats"
              @click="storeCursorPosition(index)"
              @dblclick="playUtteranceLoop(utterance, word.start)"
            >
              <span
              :style="getWordColor(word.confidence)"
              :class="{ 'pink-text': isPlayingWord(word), 'text-gray-300': isSearchActive && !isSearchMatch(word.punctuated_word) }"
              >{{ word.punctuated_word }}</span>{{ word.avoid_space ? '' : '&nbsp;'}}</span>
          </div>
          <div class="w-1/5 text-3xl text-gray-900 pl-6">
            <div class="flex">
              <i @click="updateUtteranceStatus(utterance, true)" class="fa-regular fa-circle-check cursor-pointer mr-1 opacity-20" :class="{ 'opacity-20 hover:opacity-100': utterance.approved === null, 'text-green-500 opacity-100 hover:opacity-50': utterance.approved === true }" title="Approve Segment"></i>
              <i @click="approveAllUtterancesAbove(utterance)" class="fa-regular fa-circle-up cursor-pointer opacity-20 hover:opacity-100 mr-1" title="Approve All Segments Up Until"></i>
              <i @click="updateUtteranceStatus(utterance, false)" class="fa-regular fa-circle-xmark cursor-pointer opacity-20" :class="{ 'opacity-20 hover:opacity-100': utterance.approved === null, 'text-red-500 opacity-100 hover:opacity-50': utterance.approved === false }" title="Reject Segment"></i>
            </div>
          </div>
        </span>
      </div>
      <div class="audio-player">
        <div class="progress-bar bg-green-500" :style="{ width: totalApprovalProgress + '%' }"></div>
        <div class="audio-player-container">
          <button @click="togglePlay" class="play-pause-button">
            <i :class="isPlaying ? 'fas fa-pause' : 'fas fa-play'"></i>
          </button>
          <input
            type="range"
            min="0"
            :max="audioDuration"
            v-model="currentTime"
            @input="changeCurrentTime"
            class="tracking-bar"
          />
          <div class="relative" @mouseenter="showSpeedMenu = true" @mouseleave="showSpeedMenu = false">
            <button class="ml-4 font-bold cursor-pointer">
              {{  playbackRate }}x
            </button>
            <div v-if="showSpeedMenu" class="absolute bottom-full right-0 bg-white shadow-lg rounded">
              <button @click="setPlaybackSpeed(0.5)" class="block w-full text-left px-4 py-2 hover:bg-gray-100">0.5x</button>
              <button @click="setPlaybackSpeed(1.0)" class="block w-full text-left px-4 py-2 hover:bg-gray-100">1.0x</button>
              <button @click="setPlaybackSpeed(2.0)" class="block w-full text-left px-4 py-2 hover:bg-gray-100">2.0x</button>
            </div>
          </div>
        </div>
      </div>
      <audio ref="audioPlayer" @timeupdate="updateCurrentTime" hidden></audio>
    </div>

    <!-- EDIT START/END TIMES MODAL -->
    <div v-if="isModalOpen" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="closeEditModal"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div @click="closeEditModal" class="float-right -mt-3 cursor-pointer text-gray-300"><i class="fa fa-x text-xs" /></div>

            <div>
              <h3 class="text-center text-lg font-bold mb-4">Edit Segment Times</h3>
              <div class="mb-2">
                <label for="start-time" class="font-bold">Start Time:</label>
                <div class="flex items-center">
                  <button @click="adjustTime('start', -1)" class="text-lg kbd hover:opacity-70">-</button>
                  <input type="text" id="start-time" class="block w-full rounded-md border p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 mx-2" v-model="editableSegment.start" />
                  <button @click="adjustTime('start', 1)" class="text-lg kbd hover:opacity-70">+</button>
                </div>
              </div>
              <div>
                <label for="end-time" class="font-bold">End Time:</label>
                <div class="flex items-center">
                  <button @click="adjustTime('end', -1)" class="text-lg kbd hover:opacity-70">-</button>
                  <input type="text" id="end-time" class="block w-full rounded-md border p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 mx-2" v-model="editableSegment.end" />
                  <button @click="adjustTime('end', 1)" class="text-lg kbd hover:opacity-70">+</button>
                </div>
              </div>
            </div>
            <div class="mt-4 sm:mt-4">
              <button @click="saveSegmentTimes" type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><i class="mr-2 text-sm fa fa-save"></i> Save</button>
              <button @click="playUtteranceLoop(editableSegment)" type="button" class="mt-2 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"><i class="mr-2 text-sm" :class="isLooping ? 'fas fa-pause' : 'fas fa-play'"></i> Toggle Loop Playback</button>
              <button @click="closeEditModal" type="button" class="mt-2 inline-flex w-full justify-center rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm border border-1 border-gray-300 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  </template>

  <script>
  import WebSocketService from '../services/WebSocketService';

  export default {
      name: "EditView",
      props: {
        token: String,
      },
      data() {
          return {
          currentCursorPosition: null,
          playbackRate: 1.0,
          showSpeedMenu: false,
          audio: new Audio(),
          currentTime: 0,
          isPlaying: false,
          audioDuration: 0,
          animationFrameId: null,
          selectedFile: null,
          searchTerm: '',
          isSearchActive: false,
          hoveredWord: null,
          hoveredSegment: null,
          selectedSegmentIndex: 0,
          settingsMenuOpened: false,
          smartFormatting: true,
          punctuation: true,
          segmentSilence: 0.8,
          segmentsProcessing: new Set(),
          erroredSegments: new Set(),
          loadingTranscript: false,
          model: "whisper-medium",
          // Edit times modal:
          isModalOpen: false,
          editableSegment: null,
          editableSegmentIndex: -1,
          // Looping code:
          isLooping: false,
          audioBuffer: null,
          sourceNode: null,
          audioContext: new (window.AudioContext || window.webkitAudioContext)(),
          }
        },
        watch: {
          'editableSegment.start': function(newStart) {
            if (this.isLooping) {
              this.stopCurrentLoop();
             this.playUtteranceLoop(this.editableSegment);
            }
          },
          'editableSegment.end': function(newEnd) {
            if (this.isLooping) {
              this.stopCurrentLoop();
              this.playUtteranceLoop(this.editableSegment);          
            }
          },
          'playbackRate': function() {
            if (this.isLooping) {
              this.stopCurrentLoop();
              this.playUtteranceLoop(this.utterances[this.selectedSegmentIndex]);          
            }
          }
        },
        computed: {
          id() {
            if(!this.$store.state.transcript || (this.$store.state.transcript && !this.$store.state.transcript.id)) return null;
            else return this.$store.state.transcript.id;
          },
          fileObject() {
            return this.$store.state.fileObject;
          },
          utterances() {
            if(!this.$store.state.transcript) return [];
            let transcript;
            if(this.$store.state.transcript.transcript) {
              transcript = this.$store.state.transcript.transcript;
            } else {
              transcript = this.$store.state.transcript.original_transcript;
            }
            let utterances = transcript.results && transcript.results.utterances ? transcript.results.utterances : [];
            utterances.forEach(utterance => {
              if(!utterance.hasOwnProperty('approved')) utterance.approved = null;
            });
            return utterances; 
          },
          audioSrc() {
            return this.$store.state.audioSrc ? this.$store.state.audioSrc : null;
          },
          totalApprovalProgress() {
            // Initialize a counter for the approved utterances
            let approvedUtterances = 0;
            // Iterate over each utterance
            this.utterances.forEach(utterance => {
              // If the utterance is approved, increment the counter
              if (utterance.approved !== null) {
                approvedUtterances++;
              }
            });
            // Calculate the percentage of approved utterances
            let approvalPercentage = (approvedUtterances / this.utterances.length) * 100;
            // Return the approval percentage
            return approvalPercentage;
          },
          selectedFileTruncated() {
            if(!this.selectedFile || !this.selectedFile.name) return "Editing...";
            return this.selectedFile && this.selectedFile.name && this.selectedFile.name.length > 14
              ? this.selectedFile.name.slice(0, 11) + '...'
              : this.selectedFile.name;
          },
          totalWords: function() {
            // Initialize a counter for the total words
            let totalWords = 0;
            // Iterate over each utterance
            this.utterances.forEach(utterance => {
              // Add the length of the words array in the current utterance to the total
              totalWords += utterance.words.length;
            });
            // Return the total words
            return totalWords;
          }
        },
        methods: {
          handleMovementUp(index, event) {
            if (event.shiftKey) {
              this.mergeUtteranceUp(index);
            } else {
              this.moveWordUp(index);
            }
          },
          handleMovementDown(index, event) {
            if (event.shiftKey) {
              this.mergeUtteranceDown(index);
            } else {
              this.moveWordDown(index);
            }
          },
          adjustTime(field, amount) {
            if (field === 'start') {
              let newTime = parseFloat(this.editableSegment.start) + amount;
              this.editableSegment.start = newTime >= 0 ? newTime.toString() : '0';
            } else if (field === 'end') {
              let newTime = parseFloat(this.editableSegment.end) + amount;
              this.editableSegment.end = newTime >= 0 ? newTime.toString() : '0';
            }
          },
          async openEditSegmentModal(utterance, index) {
            // Stop current loop if it's playing:
            this.stopCurrentLoop();

            if(this.segmentsProcessing.has(utterance.id)) {
              console.log("*** Waiting for force-align to complete...");
              await this.waitForForceAlignToComplete(utterance.id);
              utterance = this.utterances[index]; // Update utterance to new value after force-align
            }
            this.editableSegment = { ...utterance };
            this.editableSegmentIndex = index;
            this.isModalOpen = true;
          },
          saveSegmentTimes() {
            // Assuming editableSegment.start and editableSegment.end are in seconds
            const newStartTime = parseFloat(this.editableSegment.start);
            const newEndTime = parseFloat(this.editableSegment.end);

            if (newStartTime >= newEndTime) {
              alert("Start time must be less than end time.");
              return;
            }

            // Get the current utterance and its index
            const currentUtterance = this.utterances[this.editableSegmentIndex];

            // Adjust the times of the previous segment if necessary
            if (this.editableSegmentIndex > 0) {
              const previousUtterance = this.utterances[this.editableSegmentIndex - 1];
              if (newStartTime < previousUtterance.end) {
                previousUtterance.end = newStartTime;
                // Adjust the end time of the last word in the previous segment
                const lastWordPreviousUtterance = previousUtterance.words[previousUtterance.words.length - 1];
                lastWordPreviousUtterance.end = newStartTime;
              }
            }

            // Adjust the times of the next segment if necessary
            if (this.editableSegmentIndex < this.utterances.length - 1) {
              const nextUtterance = this.utterances[this.editableSegmentIndex + 1];
              if (newEndTime > nextUtterance.start) {
                nextUtterance.start = newEndTime;
                // Adjust the start time of the first word in the next segment
                const firstWordNextUtterance = nextUtterance.words[0];
                firstWordNextUtterance.start = newEndTime;
              }
            }

            // Update the current utterance times
            currentUtterance.start = newStartTime;
            currentUtterance.end = newEndTime;
            // Adjust the start and end times of the words in the current utterance if necessary
            // If the new start time is greater than the start of the first word, adjust it
            if (newStartTime > currentUtterance.words[0].start) {
              currentUtterance.words[0].start = newStartTime;
            }
            // If the new end time is less than the end of the last word, adjust it
            const lastWordIndex = currentUtterance.words.length - 1;
            if (newEndTime < currentUtterance.words[lastWordIndex].end) {
              currentUtterance.words[lastWordIndex].end = newEndTime;
            }

            // Save changes to the backend
            this.saveTranscriptToBackend();

            // Close the modal
            this.closeEditModal();
          },
          storeCursorPosition(index) {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              const selectedNode = range.startContainer;
              const startOffset = range.startOffset;

              // Find the contenteditable parent node
              let utteranceDiv = selectedNode.nodeType === Node.TEXT_NODE ? selectedNode.parentNode : selectedNode;
              while (utteranceDiv && typeof utteranceDiv.getAttribute === 'function' && utteranceDiv.getAttribute('contenteditable') !== 'true') {
                utteranceDiv = utteranceDiv.parentNode;
              }
              if (!utteranceDiv) return; // Did not find a contenteditable parent

              // Create a range that spans all content up to the cursor position
              const preCaretRange = document.createRange();
              preCaretRange.selectNodeContents(utteranceDiv);
              preCaretRange.setEnd(selectedNode, startOffset);

              // Use the range to calculate the character count
              const charCount = preCaretRange.toString().length;

              // Store the cursor position with the utterance index
              this.currentCursorPosition = {
                utteranceIndex: index,
                charCount: charCount,
              };
            }
          },
          generateUniqueId(utterance) {
            const utteranceString = JSON.stringify(utterance);
            let hash = 0;
            for (let i = 0; i < utteranceString.length; i++) {
              const char = utteranceString.charCodeAt(i);
              hash = (hash << 5) - hash + char;
              hash |= 0; // Convert to 32bit integer
            }
            return `segment-${hash}`;
          },
          waitForForceAlignToComplete(segmentId) {
            // Return a promise that resolves when the force align is no longer processing
            return new Promise((resolve) => {
              const checkInterval = setInterval(() => {
                if (!this.segmentsProcessing.has(segmentId)) {
                  clearInterval(checkInterval);
                  resolve();
                }
              }, 100); // Check every 100 milliseconds
            });
          },
          async splitUtteranceAtCursor() {
            if (!this.currentCursorPosition) return; // No cursor position stored

            const utteranceIndex = this.currentCursorPosition.utteranceIndex;
            const charCount = this.currentCursorPosition.charCount;
            let utterance = this.utterances[utteranceIndex];

            if(this.segmentsProcessing.has(utterance.id)) {
              console.log("*** Waiting for force-align to complete...");
              await this.waitForForceAlignToComplete(utterance.id);
              utterance = this.utterances[utteranceIndex]; // Update utterance to new value after force-align
            }

            let cumulativeLength = 0;

            // Find the word where the cursor is positioned
            let splitWordIndex = utterance.words.findIndex((word, index) => {
              // Add 1 for the space if it's not the first word
              cumulativeLength += (index > 0 ? 1 : 0) + word.punctuated_word.length;
              // Check if the cumulative length just passed the cursor position
              return cumulativeLength > charCount;
            });

            // If the cursor is at the end of the utterance, splitWordIndex will be -1
            if (splitWordIndex === -1) {
              splitWordIndex = utterance.words.length;
            }

            // console.log("split word index = ", splitWordIndex);
            // If cursor is at the end, no need to split
            if (splitWordIndex === -1) return;

            // Split the utterance into two at the splitWordIndex
            const firstPartWords = utterance.words.slice(0, splitWordIndex);
            const secondPartWords = utterance.words.slice(splitWordIndex);

            // Don't split at end
            if(!secondPartWords || secondPartWords.length === 0) return;

            // Create new utterance for the second part
            const newUtterance = {
              ...utterance,
              start: secondPartWords[0].start,
              end: utterance.end,
              words: secondPartWords,
              approved: null,
              transcript: secondPartWords.map(w => w.punctuated_word).join(' ')
            };
            newUtterance.id = this.generateUniqueId(newUtterance);

            //console.log("old utterance = ", utterance);
            //console.log("new utterance = ", newUtterance);
            // Update the original utterance for the first part
            utterance.end = firstPartWords[firstPartWords.length - 1].end;
            utterance.words = firstPartWords;
            utterance.transcript = firstPartWords.map(w => w.punctuated_word).join(' ');

            // Insert the new utterance after the current one
            this.utterances.splice(utteranceIndex + 1, 0, newUtterance);

            // After splitting, clear the stored cursor position
            this.currentCursorPosition = null;

            // Save changes
            this.saveTranscriptToBackend();
          },
          setPlaybackSpeed(rate) {
            this.playbackRate = rate;
            this.showSpeedMenu = false;
            this.$refs.audioPlayer.playbackRate = this.playbackRate;
          },
          getSegmentNumber(utterance) {
            // Find the utterance that has the same id as the utterance passed into the function
            const utteranceIndex = this.utterances.findIndex(u => u.id === utterance.id);
            // Return the index of that utterance in this.utterances
            return utteranceIndex;
          },
          exportFile() {
            if(!this.selectedFile && (!this.utterances || this.utterances.length === 0)) {
              alert("You must select a file first!");
              return;
            }

            this.utterances.forEach(utterance => {
              // Recreate the "transcript" by joining all the punctuated_words, and don't put spaces after any word that has "avoid_space":
              utterance.transcript = utterance.words.map((word, index) => {
                // Use the punctuated_word if available, otherwise use word
                let wordText = word.punctuated_word ? word.punctuated_word : word.word;
                // Add a space after the word unless avoid_space is true or it's the last word
                return word.avoid_space || index === utterance.words.length - 1 ? wordText : wordText + ' ';
              }).join('');
              // Replace all &nbsp; with normal spaces in the "transcript" property of each utterance:
              utterance.transcript = utterance.transcript.replaceAll(' ', ' ');
            });
            
            // Create a blob from the JSON of this.utterances
            const blob = new Blob([JSON.stringify(this.utterances, null, 2)], {type : 'application/json'});
            const link = document.createElement("a");
            link.download = "utterances.json";
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          },
          convertToReadableTime(timeInSeconds) {
            let hours = Math.floor(timeInSeconds / 3600);
            let minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
            let seconds = timeInSeconds - (hours * 3600) - (minutes * 60);
            let milliseconds = ((seconds % 1) * 1000).toFixed(0);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + Math.floor(seconds) : Math.floor(seconds);
            milliseconds = (milliseconds < 100) ? (milliseconds < 10) ? "00" + milliseconds : "0" + milliseconds : milliseconds;

            return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
          },
          calculateSegmentDuration(startTimeInSeconds, endTimeInSeconds) {
            return endTimeInSeconds - startTimeInSeconds;
          },
          saveTranscriptToBackend: async function() {
            if(this.utterances) {
              let obj = { transcript: { results: { utterances: this.utterances } } };
              //console.log("Obj to save = ", obj);

              let apiUrl = "https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/" + this.id;
              //const response = 
              await fetch(apiUrl, {
                method: "PATCH",
                headers: {
                  Authorization: `Token ${this.token}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
              });

              //const data = await response.json();
              //console.log("Transcript updated = ", data);
            }
          },
          handleAudioChange(fileObject = null) {
            this.$store.dispatch('updateTranscript', null);
            let file;
            if(fileObject) {
              file = fileObject && fileObject.target ? fileObject.target.files[0] : fileObject;
            } else {
              file = this.$refs.audioInput.files[0];
            }
            this.$refs.audioPlayer.src = URL.createObjectURL(
              file
            );
            this.$refs.audioPlayer.onloadedmetadata = () => {
              this.audioDuration = this.$refs.audioPlayer.duration;
              this.selectedFile = file;
              this.transcribeAudio();
            };
          },
          async loadExternalAudio(src) {
            // Load the audio in the main player:
            this.$refs.audioPlayer.src = src;
            this.$refs.audioPlayer.onloadedmetadata = () => {
              this.audioDuration = this.$refs.audioPlayer.duration;
              //this.selectedFile = file;
            };

            // Load the audio in separate buffer for precision looping:
            await this.loadAudioFile(src);
          },
          undo: async function () {
            let apiUrl = "https://engdev.theoasis.com/api/v1/labs/input/" + this.id + "/undo/";
            const response = await fetch(apiUrl, {
              method: "POST",
              headers: {
                Authorization: `Token ${this.token}`,
              }
            });
            if(response) {
              try {
                const data = await response.json();
                // Continue with your logic using 'data'
                console.log("Last transcript's data = ", data);
                if(data && data.transcript && data.transcript.results && data.transcript.results.utterances && data.transcript.results.utterances.length > 0) {
                  this.$store.dispatch('updateTranscript', data);
                } else if(data && data.original_transcript && data.original_transcript.results && data.original_transcript.results.utterances && data.original_transcript.results.utterances.length > 0) {
                  data.transcript = data.original_transcript;
                  this.$store.dispatch('updateTranscript', data);
                } else {
                  alert("There is nothing to undo!");
                }
              } catch (error) {
                alert("There is nothing to undo!");
              }
            }
          },
          transcribeAudio: async function () {
            // if(!file) {
            //   const audioInput = this.$refs.audioInput;
            //   file = audioInput.files[0];
            // }
            this.loadingTranscript = true;
            let file = this.selectedFile;
            console.log("file = ", file);
            let formData = new FormData();
            formData.append('audio_file', file, file.name);

            let apiUrl = "https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/";
            const response = await fetch(apiUrl, {
              method: "POST",
              headers: {
                Authorization: `Token ${this.token}`,
              },
              body: formData
            });

            const data = await response.json();
            console.log("Transcript created = ", data);
            data.transcript = {};
            this.$store.dispatch('updateTranscript', data);
            if (data && data.results && data.results.utterances) {
              this.utterances = data.results.utterances.map(utterance => ({ ...utterance, approved: null }));
            } else {
              console.log("Waiting for websocket response...")
              //alert("Transcription not available.");
            }
          },
          getWordColor(confidence, showGreens = false) {
            // Define a color-coding logic based on confidence (you can customize this)
            if (confidence >= 0.8) {
              if(showGreens) return { backgroundColor: "green" };
              else return { backgroundColor: "transparent" };
            } else if (confidence >= 0.6) {
              return { backgroundColor: "#ffffab" };
            } else {
              return { backgroundColor: "#ffabab" };
            }
          },
          getWordTextColor(confidence, showGreens = false) {
            // Define a color-coding logic based on confidence (you can customize this)
            if (Number(confidence) >= 0.8) {
              if(showGreens) return { color: "green" };
              else return { color: "transparent" };
            } else if (confidence >= 0.6) {
              return { color: "#ffffab" };
            } else {
              return { color: "#ffabab" };
            }
          },
          async playUtteranceAtCursor() {
            if (!this.currentCursorPosition) return; // No cursor position stored
            const utteranceIndex = this.currentCursorPosition.utteranceIndex;
            const charCount = this.currentCursorPosition.charCount;
            let utterance = this.utterances[utteranceIndex];

            let cumulativeLength = 0;
            // Find the word where the cursor is positioned
            let splitWordIndex = utterance.words.findIndex((word, index) => {
              // Add 1 for the space if it's not the first word
              cumulativeLength += (index > 0 ? 1 : 0) + word.punctuated_word.length;
              // Check if the cumulative length just passed the cursor position
              return cumulativeLength > charCount;
            });
            // If the cursor is at the end of the utterance, splitWordIndex will be -1
            if (splitWordIndex === -1) {
              splitWordIndex = utterance.words.length;
            }
            // If cursor is at the end, no need to split
            if (splitWordIndex === -1) splitWordIndex = 0;

            // Play audio
            this.playUtteranceLoop(utterance, utterance.words[splitWordIndex].start);
          },
          stopMainAudio: function() {
            if (!this.$refs.audioPlayer.paused) {
              this.$refs.audioPlayer.pause();
              this.isPlaying = false;
            }
          },
          stopCurrentLoop: function() {
            // Stop the loop if it's currently playing
            if (this.isLooping) {
              if (this.sourceNode) {
                  this.sourceNode.disconnect();
                  this.sourceNode.stop();
                  this.sourceNode = null;
                }
                this.isLooping = false;
            }
          },
          playUtteranceLoop(utterance, startTime = null) {
            if (this.isLooping) {
              this.stopCurrentLoop();
            } else {
              // Stop main audio player if it's playing:
              this.stopMainAudio();

              // Update the main player so it jumps to the start time of the double-clicked word:
              const offset = startTime ? startTime : utterance.start;
              this.$refs.audioPlayer.currentTime = offset;

              let newPlaybackRate = 1.0;
              switch(this.playbackRate) {
                case 0.5:
                  newPlaybackRate = 0.7;
                  break;
                case 2.0:
                  newPlaybackRate = 1.5;
                  break;
              }

              // Start the loop since it's not currently playing
              this.sourceNode = this.audioContext.createBufferSource();
              this.sourceNode.buffer = this.audioBuffer;
              this.sourceNode.playbackRate.value = newPlaybackRate;
              this.sourceNode.loop = true;
              this.sourceNode.loopStart = utterance.start;
              this.sourceNode.loopEnd = utterance.end;
              this.sourceNode.connect(this.audioContext.destination);

              // Start playback at offset, but loop between utterance.start and utterance.end
              this.sourceNode.start(0, offset);

              this.isLooping = true;
            }
          },
          async loadAudioFile(fileUrl) {
            const response = await fetch(fileUrl);
            const arrayBuffer = await response.arrayBuffer();
            this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
          },
          closeEditModal() {
            this.stopCurrentLoop();
            this.isModalOpen = false;
          },
          updateCurrentTime() {
            if (this.$refs.audioPlayer) {
              this.currentTime = this.$refs.audioPlayer.currentTime;
              if (
                !this.$refs.audioPlayer.paused &&
                !this.$refs.audioPlayer.ended
              ) {
                this.animationFrameId = requestAnimationFrame(
                  this.updateCurrentTime
                );
              }
            }
          },
          isPlayingWord(word) {
            if(!word) return false;
            return (
              this.currentTime >= word.start && this.currentTime <= word.end
            );
          },
          togglePlay() {
            // Stop any looping audio if it's playing:
            this.stopCurrentLoop();

            if (this.$refs.audioPlayer.paused) {
              this.$refs.audioPlayer.play();
              this.isPlaying = true;
              this.animationFrameId = requestAnimationFrame(
                this.updateCurrentTime
              );
            } else {
              this.$refs.audioPlayer.pause();
              this.isPlaying = false;
              cancelAnimationFrame(this.animationFrameId);
            }
          },
          changeCurrentTime() {
            this.$refs.audioPlayer.currentTime = this.currentTime;
            if (
              !this.$refs.audioPlayer.paused &&
              !this.$refs.audioPlayer.ended
            ) {
              cancelAnimationFrame(this.animationFrameId);
              this.animationFrameId = requestAnimationFrame(
                this.updateCurrentTime
              );
            }
          },
          searchTranscript() {
            this.isSearchActive = this.searchTerm !== '';
          },
          isSearchMatch(word) {
            return word.toLowerCase().includes(this.searchTerm.toLowerCase());
          },
          showStats(word) {
            this.hoveredWord = word;
          },
          clearStats() {
            this.hoveredWord = null;
          },
          showSegmentOptions(utterance) {
            this.hoveredSegment = utterance;
          },
          clearSegment() {
            this.hoveredSegment = null;
          },
          async addNewUtteranceAfter(index) {
            // Check if the index is within the bounds of the utterances array
            if (index >= 0 && index < this.utterances.length) {
              // Get the current and the next utterance
              let currentUtterance = this.utterances[index];
              const nextUtterance = this.utterances[index + 1];

              // Wait for pending force align job to complete, if necessary
              if(this.segmentsProcessing.has(currentUtterance.id)) {
                console.log("*** Waiting for force-align to complete...");
                await this.waitForForceAlignToComplete(currentUtterance.id);
                currentUtterance = this.utterances[index]; // Update utterance to new value after force-align
              }

              // Calculate the start and end times for the new utterance
              const newUtteranceStartTime = currentUtterance.end;
              let newUtteranceEndTime;
              if (nextUtterance) {
                // If there is a next utterance, set the end time to the midpoint between the two utterances
                newUtteranceEndTime = nextUtterance.start;
              } else {
                // If there is no next utterance, set the end time to the current end time
                newUtteranceEndTime = currentUtterance.end;
              }

              // Create a new utterance object
              const newUtterance = {
                start: newUtteranceStartTime,
                end: newUtteranceEndTime,
                words: [], // No words initially
                approved: null, // Approval status is null initially
                transcript: '' // Empty transcript initially
              };
              newUtterance.id = this.generateUniqueId(newUtterance); // Generate a unique ID for the new utterance

              // Insert the new utterance into the utterances array after the current utterance
              this.utterances.splice(index + 1, 0, newUtterance);

              // Save changes
              this.saveTranscriptToBackend();
            } else {
              console.error('Invalid index for adding new utterance.');
            }
          },
          deleteUtterance(index) {
            // Check if the index is within the bounds of the utterances array
            if (index >= 0 && index < this.utterances.length) {
              // If there is a previous utterance, adjust its end time to the start time of the next utterance
              if (index > 0 && this.utterances.length > 1) {
                const nextUtteranceStart = this.utterances[index + 1] ? this.utterances[index + 1].start : this.utterances[index].end;
                this.utterances[index - 1].end = nextUtteranceStart;
              }

              // If there is a next utterance, adjust its start time to the end time of the previous utterance
              if (index < this.utterances.length - 1 && this.utterances.length > 1) {
                const prevUtteranceEnd = this.utterances[index - 1] ? this.utterances[index - 1].end : this.utterances[index].start;
                this.utterances[index + 1].start = prevUtteranceEnd;
              }

              // Remove the utterance from the array
              this.utterances.splice(index, 1);

              // Save changes
              this.saveTranscriptToBackend();
            } else {
              console.error('Invalid index for deleting utterance.');
            }
          },
          mergeUtteranceUp(utteranceIndex) {
            // Check if there is an utterance below the current one
            if (utteranceIndex < this.utterances.length - 1) {
              // Get the current and previous utterances
              const currentUtterance = this.utterances[utteranceIndex];
              const nextUtterance = this.utterances[utteranceIndex + 1];

              // Add all words from the current utterance to the end of the previous utterance
              currentUtterance.words = currentUtterance.words.concat(nextUtterance.words);

              // Update the end time of the previous utterance
              currentUtterance.end = nextUtterance.end;

              // Update the transcript of the previous utterance
              currentUtterance.transcript = currentUtterance.words.map(w => w.punctuated_word ? w.punctuated_word : w.word).join(' ');

              // Remove the next utterance
              this.utterances.splice(utteranceIndex+1, 1);

              // Save changes
              this.saveTranscriptToBackend();
            }
          },
          mergeUtteranceDown(utteranceIndex) {
            // Check if there is an utterance below the current one
            if (utteranceIndex < this.utterances.length - 1) {
              // Get the current and previous utterances
              const currentUtterance = this.utterances[utteranceIndex];
              const nextUtterance = this.utterances[utteranceIndex + 1];

              // Add all words from the current utterance to the end of the previous utterance
              nextUtterance.words = currentUtterance.words.concat(nextUtterance.words);

              // Update the end time of the previous utterance
              nextUtterance.start = currentUtterance.end;

              // Update the transcript of the previous utterance
              nextUtterance.transcript = nextUtterance.words.map(w => w.punctuated_word ? w.punctuated_word : w.word).join(' ');

              // Remove the next utterance
              this.utterances.splice(utteranceIndex, 1);

              // Save changes
              this.saveTranscriptToBackend();
            }
          },
          moveWordUp(utteranceIndex) {
            // Check if there is an utterance below the current one
            if (utteranceIndex < this.utterances.length - 1) {
              // Get the current and next utterances
              const currentUtterance = this.utterances[utteranceIndex];
              const nextUtterance = this.utterances[utteranceIndex + 1];
              let wordIndex = 0;

              // Get the word to move
              const word = nextUtterance.words[wordIndex];

              // Remove the word from the next utterance
              nextUtterance.words.splice(wordIndex, 1);

              // If the next utterance has no words left, remove it
              if (nextUtterance.words.length === 0) {
                this.utterances.splice(utteranceIndex + 1, 1);
              } else {
                // Update the start time of the next utterance
                nextUtterance.start = nextUtterance.words[0].start;
                // Update the transcript of the next utterance
                nextUtterance.transcript = nextUtterance.words.map(w => w.word).join(' ');
              }

              // Add the word to the end of the current utterance
              currentUtterance.words.push(word);

              // Update the end time of the current utterance
              currentUtterance.end = currentUtterance.words[currentUtterance.words.length - 1].end;
              // Update the transcript of the current utterance
              currentUtterance.transcript = currentUtterance.words.map(w => w.word).join(' ');

              // Save changes
              this.saveTranscriptToBackend();
            }
          },
          moveWordDown(utteranceIndex) {
            // Check if there is an utterance below the current one
            if (utteranceIndex < this.utterances.length - 1) {
              // Get the current and next utterances
              const currentUtterance = this.utterances[utteranceIndex];
              const nextUtterance = this.utterances[utteranceIndex + 1];
              let wordIndex = currentUtterance.words.length - 1;

              // Get the word to move
              const word = currentUtterance.words[wordIndex];

              // Remove the word from the current utterance
              currentUtterance.words.splice(wordIndex, 1);

              // If the current utterance has no words left, remove it
              if (currentUtterance.words.length === 0) {
                this.utterances.splice(utteranceIndex, 1);
              } else {
                // Update the end time of the current utterance
                currentUtterance.end = currentUtterance.words[currentUtterance.words.length - 1].end;
                // Update the transcript of the current utterance
                currentUtterance.transcript = currentUtterance.words.map(w => w.word).join(' ');
              }

              // Add the word to the start of the next utterance
              nextUtterance.words.unshift(word);

              // Update the start time of the next utterance
              nextUtterance.start = nextUtterance.words[0].start;
              // Update the transcript of the next utterance
              nextUtterance.transcript = nextUtterance.words.map(w => w.word).join(' ');

              // Save changes
              this.saveTranscriptToBackend();
            }
          },
          async updateUtteranceStatus(utterance, val) {
            if(this.segmentsProcessing.has(utterance.id)) {
              console.log("*** Waiting for force-align to complete...");
              await this.waitForForceAlignToComplete(utterance.id);
              // Update utterance to new value after force-align
              const index = this.utterances.findIndex(u => u.id === utterance.id);
              utterance = this.utterances[index];
            }

            if(utterance.approved === undefined) utterance.approved = null;
            if(val === true) {
              if(utterance.approved === true) utterance.approved = null;
              else utterance.approved = true
            } else if(val === false) {
              if(utterance.approved === false) utterance.approved = null;
              else utterance.approved = false;
            } else if(val === null) {
              utterance.approved = null;
            }
            this.saveTranscriptToBackend();
            this.$forceUpdate();
          },
          approveAllUtterancesAbove(utterance) {
            // Find the index of the utterance in the array
            let utteranceIndex = this.utterances.indexOf(utterance);
            // If the utterance is found in the array
            if (utteranceIndex !== -1) {
              // Loop through all the utterances up to and including the current one
              for (let i = 0; i <= utteranceIndex; i++) {
                // Set the "approved" property to true
                if(this.utterances[i].approved === null) this.utterances[i].approved = true;
              }
              this.saveTranscriptToBackend();
            }
          },
          handleSpacebar(event) {
            // Check if the target is not an input or contenteditable element
            if (event.target.tagName.toLowerCase() !== 'input' && event.target.getAttribute('contenteditable') !== 'true') {
              // SPACE - Toggle playing entire audio file
              if (event.code === 'Space') {
                this.togglePlay();
                event.preventDefault(); // Prevent the default action (scrolling)
              }
            }
            // CTRL+A - Approve selected segment
            if (event.ctrlKey && event.code === 'KeyA') {
              if(this.utterances[this.selectedSegmentIndex].approved === true)
                this.updateUtteranceStatus(this.utterances[this.selectedSegmentIndex], null);
              else
                this.updateUtteranceStatus(this.utterances[this.selectedSegmentIndex], true);
              event.preventDefault();
            }
            // CTRL+R - Reject selected segment
            else if (event.ctrlKey && event.code === 'KeyR') {
              if(this.utterances[this.selectedSegmentIndex].approved === false)
                this.updateUtteranceStatus(this.utterances[this.selectedSegmentIndex], null);
              else
                this.updateUtteranceStatus(this.utterances[this.selectedSegmentIndex], false);
              event.preventDefault();
            }
            // CTRL+[ - Jump to previous segment
            else if (event.ctrlKey && event.code === 'BracketLeft') {
              if(this.selectedSegmentIndex > 0) this.selectedSegmentIndex--;
              event.preventDefault();
            }
            // CTRL+] - Jump to next segment
            else if (event.ctrlKey && event.code === 'BracketRight') {
              if(this.selectedSegmentIndex < this.utterances.length - 1) this.selectedSegmentIndex++;
              event.preventDefault();
            }
            // CTRL+P - Loop segment from cursor
            else if (event.ctrlKey && event.code === 'KeyP') {
              this.playUtteranceAtCursor();
              event.preventDefault();
            }
          },
          triggerForceAlignAndSave(id, index) {
            const utteranceElement = this.$refs[`utteranceText-${id}`];
            if (utteranceElement) {
              const newTranscript = utteranceElement[0].innerText;
              this.erroredSegments.delete(id);
              this.forceAlignAndSave({ target: { innerText: newTranscript } }, index, false);
            }
          },
          async forceAlignAndSave(event, index, checkForChanges = true) {
            this.storeCursorPosition(index);
            let utterance = this.utterances[index];
            let newTranscript = event.target.innerText;
            //console.log(newTranscript, utterance.transcript, typeof newTranscript, typeof utterance.transcript);
            //if(newTranscript.trim().replace(/\s+/g, ' ') !== utterance.transcript.trim().replace(/\s+/g, ' ')) {
            const normalizeText = (text) => text.replace(/\s/g, '');
            if (!checkForChanges || (normalizeText(newTranscript) !== normalizeText(utterance.transcript))) {              
              this.segmentsProcessing.add(utterance.id);
              console.log("Segment changed! Force aligning...", utterance, newTranscript);
              let obj = {
                  transcript_verification_input_id: this.id, 
                  uuid: utterance.id, 
                  transcript: newTranscript, 
                  ts_start: this.convertToReadableTime(utterance.start), 
                  ts_end: this.convertToReadableTime(utterance.end)
                };
                WebSocketService.sendMessage('forceAlignAudioSegment: ' + JSON.stringify(obj));
                //console.log("Force align job created!s");//, data);
            } else {
              console.log("No changes to segment.");
            }
          },
          handleIncomingMessage(message) {
            if (JSON.parse(message)) message = JSON.parse(message); // Null check if we can parse the message into json, otherwise it might be a string and we can continue as is
            //console.log('[EDIT] Received websocket message in app.vue:', message);
            if(message && message.type && message.type == "transcript_verification_input_completed") {
              let utterances = message.result.results.utterances.map(utterance => ({ ...utterance, approved: null }));
              message.result.results.utterances = utterances;
              console.log("Transcribed! ", message);
              if(message.id)  this.$router.replace({ path: `/edit/${message.id}` });
              this.loadingTranscript = false;
              //this.$store.dispatch('updateTranscript', message);
              this.$store.dispatch('updateTranscriptResults', message.result.results);
            }
            else if(message && message.type && message.type == "force_align_completed") {

              if(message.result.words && message.result.words.length > 0) {

                let originalSegment = this.utterances.find(s => s.id === message.uuid); // Find the original segment in the transcript
                let firstWordStart = message.result.words[0].start ? message.result.words[0].start : 0; // Grab the first word's start time, as we'll need to reference this delay when we're timeshifting later

                // DEBUG TIMESTAMPS (BEFORE)
                console.log("--- TIMESTAMPS FROM BACKEND ---");
                message.result.words.forEach((word) => {
                  console.log(word.start + " - " + word.end + ", " + word.word);
                });


                // Function to find the next word with a valid start time
                function findNextValidWord(words, startIndex) {
                  for (let i = startIndex; i < words.length; i++) {
                    if (words[i].case !== "not-found-in-audio") {
                      return words[i];
                    }
                  }
                  return null;
                }

                // Function to find the previous word with a valid end time
                function findPreviousValidWord(words, startIndex) {
                  for (let i = startIndex; i >= 0; i--) {
                    if (words[i].case !== "not-found-in-audio") {
                      return words[i];
                    }
                  }
                  return null;
                }

                // Iterate through every word in message.result.words
                message.result.words.forEach((word, index, array) => {
                  // 1. UPDATE PUNCTUATION & CONFIDENCE:
                  word.confidence = 1.0; // Set confidence to 1.0 for every word
                  word.punctuated_word = word.punctuated_word ? word.punctuated_word : word.word; // Set the punctuate_word parameter since the UI needs it and the backend sometimes doesn't return it.

                  // Skip if this word is not the start of a sequence of "not-found-in-audio" words
                  if (word.case === "not-found-in-audio" && (index === 0 || array[index - 1].case !== "not-found-in-audio")) {
                    // Find the end of the sequence of "not-found-in-audio" words
                    let sequenceEnd = index;
                    while (sequenceEnd < array.length && array[sequenceEnd].case === "not-found-in-audio") {
                      sequenceEnd++;
                    }
                    sequenceEnd--; // Adjust because the while loop stops at the first non-errored word

                    // Find the previous and next words with valid timestamps
                    const prevWord = findPreviousValidWord(array, index - 1);
                    const nextWord = findNextValidWord(array, sequenceEnd + 1);

                    // Calculate the start and end times based on adjacent words
                    const prevEndTime = prevWord ? prevWord.end : 0;
                    const nextStartTime = nextWord ? nextWord.start : (originalSegment.end - originalSegment.start);

                    console.log("*** start-end of errored segment! = ", prevEndTime, nextStartTime)

                    // Calculate the duration to distribute among the errored words
                    const duration = nextStartTime - prevEndTime;
                    const erroredWordsCount = sequenceEnd - index + 1;
                    const avgDuration = duration / erroredWordsCount;

                    // Assign the estimated start and end times to the errored words
                    let accumulatedDuration = 0;
                    for (let i = index; i <= sequenceEnd; i++) {
                      array[i].start = prevEndTime + accumulatedDuration;
                      accumulatedDuration += avgDuration;
                      array[i].end = array[i].start + avgDuration;
                    }
                    console.log("--- TIMESTAMPS AFTER FIXING ERRORS ---");
                    array.forEach((word) => {
                      console.log(word.start + " - " + word.end + ", " + word.word);
                    });
                  }
                });


                message.result.words.forEach((word, index, array) => {
                  // 3. TIMESHIFT RELATIVE TO WHOLE TRANSCRIPT: - Update the word's "start" and "end" values to be timeshifted starting from the originalSegment.start
                  let duration = word.end - word.start; // TODO: Decide if we need to move this?
                  // First word
                  if (index === 0) {
                    word.start = originalSegment.start;
                    word.end = originalSegment.start + duration;
                  }
                  // Last word
                  else if (index === array.length - 1) {
                    word.start = originalSegment.start + (word.start - firstWordStart);
                    word.end = word.start + duration; // OLD: originalSegment.end;
                  }
                  // Every other word
                  else {
                    word.start = originalSegment.start + (word.start - firstWordStart);
                    word.end = word.start + duration;
                  }
                });

                // DEBUG TIMESTAMPS (AFTER)
                console.log("--- TIMESTAMPS AFTER SHIFTING ---");
                message.result.words.forEach((word) => {
                  console.log(word.start + " - " + word.end + ", " + word.word);
                });

                // 4. UPDATE THE UI - Find the matching utterance in vuex and update it with the new "transcript" and "word" values
                let payload = {
                  uuid: message.uuid,
                  result: message.result
                };
                this.$store.dispatch('updateUtterance', payload);
                this.segmentsProcessing.delete(message.uuid); // Stop the loader for the segment
                console.log("Force aligned segment! ", message);
                this.$forceUpdate();

                // 5. SAVE TRANSCRIPT TO BACKEND
                this.saveTranscriptToBackend();

              } else {
                alert("No words returned from force align!");
                this.segmentsProcessing.delete(message.uuid); // Stop the loader for the segment
                this.erroredSegments.add(message.uuid); // Show retry button
              }
            }
          },
          fetchTranscript(transcript_id) {
            fetch('https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/' + transcript_id + '/', {
                method: "GET",
                headers: {
                    Authorization: `Token ${this.token}`,
                },
                mode: "cors",
                credentials: "omit"
            })
            .then(response => response.json())
            .then(transcript => {
                console.log("Transcript Data = ", transcript);
                this.$store.dispatch('updateFileObject', null);
                this.$store.dispatch('updateAudioSrc', transcript.audio_file);
                if(this.audioSrc) {
                  this.loadExternalAudio(this.audioSrc);
                }
                // TODO: Remove this and instead rely on the approval values from the backend. But first we need to save those
                let utterances = transcript.original_transcript.results.utterances.map(utterance => ({ ...utterance, approved: null }));
                transcript.original_transcript.results.utterances = utterances;
                this.$store.dispatch("updateTranscript", transcript);
                //this.transcripts = data.results.filter(i => !i.deleted);
            });
          }
        },
        mounted() {
          if(this.token) {
              WebSocketService.socketCheckIfReadyThenCallback(() => {
                  WebSocketService.sendMessage('getUser');
              });
              WebSocketService.registerMessageHandler(this.handleIncomingMessage);
          }
          else if(!this.token || this.token == null) {
              this.signOut();
          }

          const transcriptId = this.$route.params.transcript_id;
          if(transcriptId) {
            console.log("Fetching full transcript for ID = ", transcriptId);
            this.fetchTranscript(transcriptId);
          }

          console.log("fileObject", this.fileObject);
          if(this.fileObject) this.handleAudioChange(this.fileObject);

          console.log("Audio Src = ", this.audioSrc);
          if(this.audioSrc) {
            this.loadExternalAudio(this.audioSrc);
          }

          window.addEventListener('keydown', this.handleSpacebar);
        },
        beforeUnmount() {
          // Unset audioSrc so that it doesn't play the old audio when switching between transcripts:
          this.$store.dispatch('updateAudioSrc', null);

          window.removeEventListener('keydown', this.handleSpacebar);
          if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
          }

          // Stop all audio:
          this.stopCurrentLoop();
          this.stopMainAudio();
          
        }
      }
  </script>

  <style>
      .transcription {
        /* border: 1px solid #ccc; */
        padding: 10px;
        /* margin-top: 20px; */
        height: 76vh;
        overflow-y: auto;
        outline: none;
      }
      .button-pink {
        color: white;
        background: #ff5da1;
      }
      .pink-text {
        color: #ff5da1;
      }
      .pink-bg {
        background-color: #ff5da1;
      }
      .audio-player {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #efeeee;
        background-color: white;
        padding: 20px;
        left: 0;
      }
      .audio-player-container {
        display: flex;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .progress-bar {
        position: absolute;
        height: 5px;
        top: 0;
        left: 0;
      }
      .play-pause-button {
        margin-right: 10px;
      }
      .tracking-bar {
        flex-grow: 1;
      }

      .kbd {
        border: 1px solid #babfc5;
        border-top-color: rgb(186, 191, 197);
        box-shadow: rgba(12, 13, 14, 0.15) 0px 1px 1px 0px, rgb(255, 255, 255) 0px 1px 0px 0px inset;
        background-color: #e3e6e8;
        border-radius: 4px;
        color: #0c0d0e;
        display: inline-block;
        /* font-family: var(--ff-sans); */
        font-size: 11px;
        /* line-height: 1.5; */
        margin: 0 0.1em;
        overflow-wrap: break-word;
        padding: 0.1em 0.6em;
        text-shadow: rgb(255, 255, 255) 0px 1px 0px;
      }

      .actions .kbd {
        font-size: 8px;
        padding: 0 0.6em;
        cursor: pointer;
      }

      .mr-6 {
        margin-right: 1.5rem !important;
      }
      .mr-1 {
        margin-right: 0.25rem !important;
      }

      @keyframes pulse {
        0%, 100% {
          background-color: transparent;
        }
        50% {
          background-color: #eeeeee;
        }
      }
      .pulse-bg {
        animation: pulse 2s infinite;
      }
  </style>
