// src/router.js
import { createRouter, createWebHistory } from 'vue-router'; // Import specific functions
import Home from './views/Home.vue';
import Edit from './views/Edit.vue';
import User from './views/User.vue';
import SignIn from './views/SignIn.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/edit/:transcript_id?', name: "Edit", component: Edit },
  { path: '/user/:user_id?', name: "User", component: User },
  { path: '/signin', component: SignIn },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
