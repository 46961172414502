//import { createApp } from 'vue';
import { createStore } from 'vuex';

const store = createStore({
  state: {
    fileObject: null,
    transcript: null,
    audioSrc: null
  },
  mutations: {
    setFileObject(state, file) {
      state.fileObject = file;
    },
    setTranscript(state, obj) {
      state.transcript = obj;
    },
    setAudioSrc(state, src) {
      state.audioSrc = src;
    },
    setTranscriptResults(state, results) {
      if(!state.transcript.transcript.results) state.transcript.transcript.results = {};
      state.transcript.transcript.results = results;
    },
    /*
    setUtterance(state, payload) {
      let foundSegment = state.transcript.transcript.results.utterances.find(s => s.id === payload.uuid);
      if (foundSegment) {
        foundSegment.transcript = payload.result.transcript;
        foundSegment.words = payload.result.words;
        foundSegment.start = foundSegment.words[0].start;
        foundSegment.end = foundSegment.words[foundSegment.words.length - 1].end;
      }
    },
    */
    setUtterance(state, payload) {
      if(!state.transcript.transcript) state.transcript.transcript = state.transcript.original_transcript;
      let index = state.transcript.transcript.results.utterances.findIndex(s => s.id === payload.uuid);
      if (index !== -1) {
        let updatedSegment = {
          ...state.transcript.transcript.results.utterances[index],
          transcript: payload.result.transcript,
          words: payload.result.words,
          start: payload.result.words[0].start,
          //end: payload.result.words[payload.result.words.length - 1].end
        };
        // Create a new array with the updated segment
        let newArray = [
          ...state.transcript.transcript.results.utterances.slice(0, index),
          updatedSegment,
          ...state.transcript.transcript.results.utterances.slice(index + 1)
        ];
        // Replace the old array with the new one
        state.transcript.transcript.results.utterances = newArray;
      }
    }
  },
  actions: {
    updateFileObject({ commit }, file) {
      commit('setFileObject', file);
    },
    updateTranscript({ commit }, obj) {
      commit('setTranscript', obj);
    },
    updateAudioSrc({ commit }, src) {
      commit('setAudioSrc', src);
    },
    updateTranscriptResults({ commit }, results) {
      commit('setTranscriptResults', results);
    },
    updateUtterance({ commit }, payload) {
      commit('setUtterance', payload);
    },
  },
});

export default store;
