<template>
    <div class="flex flex-col min-h-screen overflow-hidden">
  
      <!-- Page content -->
      <main class="grow">
  
        <div class="mt-32 flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div class="w-full max-w-md space-y-8">
            <div>
              <img class="mx-auto h-12 w-auto" src="/oasis.png" alt="OASIS">
              <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Create Your Account</h2>
              <p class="mt-2 text-center text-sm text-gray-600">
                or login
              </p>
            </div>
  
            <form v-if="!showAuthScreen" class="mt-8 space-y-2">
              <input type="hidden" name="remember" value="true">
              <VueTelInput ref="inputRef" @keydown="onEnterKeyPress" :default-country="'US'" v-model="phone" @input="log" @country-changed="log" mode="international"></VueTelInput>
                <div class="w-full text-gray-400 text-sm text-center -mt-6">
                  OR
                </div>
              <div class="-space-y-px rounded-md shadow-sm">
                <div>
                  <label for="email-address" class="sr-only">Email address</label>
                  <input id="email-address" @keydown="onEnterKeyPress" v-model="email" name="email" type="text" required class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="✉️  Email address">
                </div>
                <!-- <div>
                  <label for="password" class="sr-only">Password</label>
                  <input id="password" name="password" type="password" autocomplete="current-password" required class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Password">
                </div> -->
              </div>
  
              <!-- <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                  <label for="remember-me" class="ml-2 block text-sm text-gray-900">Remember me for the next 30 days</label>
                </div>
  
                <div class="text-sm">
                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password?</a>
                </div>
              </div> -->
  
              <div>
                <div @click="loginNow()" class="cursor-pointer group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-60 pink-bg py-2 px-4 text-sm font-medium text-white hover:bg-indigo-70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                    <!-- Heroicon name: mini/lock-closed -->
                    <svg class="h-5 w-5 text-rose-400 group-hover:text-rose-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  Let Me In
                </div>
              </div>
            </form>
  
            <div v-else class="mt-8 space-y-6">
              <input type="hidden" name="remember" value="true">
              <div class="-space-y-px rounded-md shadow-sm">
                <div>
                  <label for="auth-code" class="sr-only">Auth Code</label>
                  <input v-model="auth" @keydown="onEnterKeyPressAuth" id="auth-code" autocomplete="one-time-code" name="auth-code" type="text" required class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Auth Code">
                </div>
                <!-- <div>
                  <label for="password" class="sr-only">Password</label>
                  <input id="password" name="password" type="password" autocomplete="current-password" required class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Password">
                </div> -->
              </div>
  
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input v-model="remember" id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 pink-text focus:ring-indigo-500">
                  <label for="remember-me" class="ml-2 block text-sm text-gray-900">Remember me for the next 30 days</label>
                </div>
              </div>
  
              <div>
                <div @click="authNow()" class="cursor-pointer group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-60 pink-bg py-2 px-4 text-sm font-medium text-white hover:bg-indigo-70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                :disabled="!auth || auth.length === 0" :class="{ 'opacity-50': !auth || auth.length === 0 }">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                    <!-- Heroicon name: mini/lock-closed -->
                    <svg class="h-5 w-5 text-rose-400 group-hover:text-rose-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                    </svg>
                  </span>
                  Confirm
                </div>
              </div>
            </div>
  
          </div>
        </div>
  
      </main>
  
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { VueTelInput } from 'vue3-tel-input';
  import 'vue3-tel-input/dist/vue3-tel-input.css'
  
  export default {
    name: 'SignIn',
    components: {
      VueTelInput
    },
    props: {
      token: String,
      user: Object
    },
    data () {
      return {
        enteredPhone: '',
        phone: ref(null),
        email: null,
        showAuthScreen: false,
        auth: null,
        remember: true
      }
    },
    mounted() {
      if(localStorage.token && localStorage.user_id) {
        let data = { token: localStorage.token, user_id: localStorage.user_id };
        this.$emit("token", data);
        // Redirect to the dashboard:
        this.$router.push('/');
      }
    },
    methods: {
      onEnterKeyPressAuth(event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
          this.authNow();
        }
      },
      onEnterKeyPress(event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
          this.loginNow();
        }
      },
      log() {
        const telephoneInputRef = ref(null);
        this.$nextTick(() => {
            telephoneInputRef.value = document.querySelector('input[name="telephone"]');
            if (telephoneInputRef.value && telephoneInputRef.value.value) {
            this.enteredPhone = telephoneInputRef.value.value.replace(/ /g, '');
            }
        });
      },
      loginNow() {
        let bodyObj = {};
        if(this.enteredPhone) {
          bodyObj.phonenumber = this.enteredPhone;
        } else if(this.email) {
          bodyObj.email = this.email.toLowerCase();
        } else {
          return;
        }
        fetch(this.apiHost + '/api/v1/account/phone', {
          method: 'POST',
          body: JSON.stringify(bodyObj),
          headers: {
            'Content-Type': 'application/json'
          },
          mode: "cors",
          credentials: "omit"
        })
        .then(response => response.json())
        .then(() => {
          // Successful auth code sent:
          this.showAuthScreen = true;
        })
        .catch(error => console.error(error));
      },
      authNow() {
        if(this.auth) {
          let bodyObj = { code: this.auth };
          if(this.enteredPhone) {
            bodyObj.phonenumber = this.enteredPhone;
          } else if(this.email) {
            bodyObj.email = this.email.toLowerCase();
          } else {
            return;
          }
          fetch(this.apiHost + '/api/v1/account', {
            method: 'POST',
            body: JSON.stringify(bodyObj),
            headers: {
              'Content-Type': 'application/json',
            },
            mode: "cors",
            credentials: "omit"
          })
          .then(response => response.json())
          .then(data => {
            // Successful auth code verified
            // Emit the auth token to the parent component:
            this.$emit("token", data);
            //if(this.remember) { 
              localStorage.token = data.token;
              localStorage.user_id = data.user_id;
            //}
            // Redirect to the dashboard:
            //this.$router.push('/');
          })
          .catch(error => console.error(error));
        }
      }
    }
  }
  </script>
  