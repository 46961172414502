/**
 * @description WebSocket Service for managing WebSocket connections.
 * @methods
 * - {@link connect}: Establishes a WebSocket connection with the given user token.
 * - {@link sendMessage}: Sends a message over the WebSocket connection.
 * - {@link registerMessageHandler}: Registers a message handler function to process received WebSocket messages.
 * - {@link socketCheckIfReadyThenCallback}: Registers a callback function to be invoked when the WebSocket connection is ready.
 * - {@link isConnectionActive}: Checks if the WebSocket connection is active and ready to send and receive messages.
 * - {@link disconnect}: Disconnects from the WebSocket by closing the connection.
 */
export default {
    /**
     * @description  The WebSocket instance.
     * @type {WebSocket}
     */
    socket: null,
    /**
     * @description  A flag indicating whether the WebSocket connection is ready.
     * @type {boolean}
     */
    isReady: false,
    /**
     * @description Callback function to handle received WebSocket messages.
     * @type {Function|null}
     */
    onMessageReceived: null,
    /**
     * @description Callback function to handle WebSocket readiness.
     * @type {Function|null}
     */
    onWebSocketReady: null,
    wssHost: null,
    token: null,
    /**
     * @description Establishes a WebSocket connection with the given user token.
     * @method
     * @param {string} userToken - The user token to authenticate the WebSocket connection.
     * @see WebSocketService#connect
     */
    connect(wssHostUrl, userToken) {
        this.socket = new WebSocket(wssHostUrl + `/ws/?token=${userToken}`);
        console.log("connecting...");

        this.socket.onopen = () => {
            this.wssHost = wssHostUrl;
            this.token = userToken;
            this.isReady = true;
            console.log("socket opened...")
            if (typeof this.onWebSocketReady === 'function') {
                this.onWebSocketReady();
                console.log("socket ready");
            }
        };
    
        this.socket.onmessage = (event) => {
    
            if (this.onMessageReceived) {
            this.onMessageReceived(event.data);
            }
        };
    
        this.socket.onclose = () => {
            this.isReady = false;
        };
    
        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.isReady = false;
        }
    },
    /**
     * @description Sends a message over the WebSocket connection.
     * @method
     * @param {string} message - The message to send.
     */
    sendMessage(message) {
      const attemptSend = () => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(message);
        } else {
          console.error('WebSocket connection is not open.');
        }
      };
    
      if (this.isReady) {
        attemptSend();
      } else {
        console.log('WebSocket connection is not ready. Attempting to connect...');
        this.connect(this.wssHost, this.token);
        this.socketCheckIfReadyThenCallback(attemptSend);
      }
    },
    /**
     * @description Registers a message handler function to process received WebSocket messages.
     * @method
     * @param {Function} handler - The function to handle received messages.
     */
    registerMessageHandler(handler) {
      this.onMessageReceived = handler;
    },
    /**
     * @description Registers a callback function to be invoked when the WebSocket connection is ready.
     * @method
     * @param {Function} callback - The callback function to execute on WebSocket readiness.
    */
    socketCheckIfReadyThenCallback(callback) {
        this.onWebSocketReady = callback;
    },
    /**
     * @description Checks if the WebSocket connection is active and ready to send and receive messages.
     * @method
     * @returns {boolean} - `true` if the connection is active, `false` otherwise.
     */
    isConnectionActive() {
        if (this.socket) {
            return this.socket.readyState === WebSocket.OPEN;
        }
        return false;
    },
    /**
     * @description Disconnects from the WebSocket by closing the connection.
     * @method
     */
    disconnect() {
      if (this.socket) {
        this.socket.close();
        this.isReady = false;
      }
    },
  };