import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'; // Import your Vuex store

//createApp(App).mount('#app')
const app = createApp(App);

app.config.globalProperties.apiHost = 'https://engdev.theoasis.com';
app.config.globalProperties.wssHost = 'wss://engdev.theoasis.com';

app.use(router);
app.use(store); // Use the Vuex store
app.mount('#app');