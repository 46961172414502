<template>
  <div id="app">
    <router-view 
      @signOut="signOut"
      @token="gotToken"
      @filePicked="filePicked"
      :token="token"
      :selectedFileinApp="pickedFile"
    ></router-view>
  </div>
</template>

<script>
import WebSocketService from './services/WebSocketService';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      user: null,
      token: null,
      user_id: null,
      pickedFile: null,
    }
  },
  created() {
    if (localStorage && localStorage.token && localStorage.user_id) {
      this.token = localStorage.token;
      this.user_id = localStorage.user_id;
      if (!WebSocketService.isConnectionActive()) {
        WebSocketService.connect(this.wssHost, this.token);
      }
      WebSocketService.socketCheckIfReadyThenCallback(() => {
        WebSocketService.sendMessage('getUser');
        //this.showSignupModal = false;
      });
      // Register an event handler to watch for incoming messages
      WebSocketService.registerMessageHandler(this.handleIncomingMessage);
    } else {
      this.signOut();
      this.$router.push('/signin');
    }
  },
  methods: {
    filePicked(file) {
      this.pickedFile = file;
      this.$router.push('/edit');
    },
    gotToken(token) {
      this.token = token.token;
      this.user_id = token.user_id;
      console.log("here", token, this.token, this.user_d);
      this.fetchUserInfo(token.token);
    },
    fetchUserInfo: function(token) {
      if (!WebSocketService.isConnectionActive()) {
        WebSocketService.connect(this.wssHost, token);
      }
      // Register the event handler to watch for incoming messages
      WebSocketService.registerMessageHandler(this.handleIncomingMessage);
      // Register a callback to watch for WebSocket readiness
      WebSocketService.socketCheckIfReadyThenCallback(() => {
        console.log("sending getUser");
        WebSocketService.sendMessage('getUser');
        //this.showSignupModal = false;
      });
    },
    handleIncomingMessage(message) {
      if (JSON.parse(message)) message = JSON.parse(message); // Null check if we can parse the message into json, otherwise it might be a string and we can continue as is
      console.log('Received websocket message in app.vue:', message);
      if (message && message.user_data) {
        this.user = message.user_data;
        this.checkLogin();
      } else if(message && message.type && message.type == "transcript_verification_input_completed") {
        console.log("Transcribed! ", message.result.results); //.channels[0].alternatives[0]);
        let utterances = message.result.results.utterances.map(utterance => ({ ...utterance, approved: null }));
        message.result.results.utterances = utterances;
        this.$store.dispatch('updateTranscript', message);
      }
    },
    checkLogin: function() {
      if(this.user && this.user.is_staff) {
        this.noLogin = false;
        this.canSendLoginRequest = false;
        localStorage.token = this.token;
        localStorage.user_id = this.user_id;
        if(this.$router.currentRoute.value.path == '/signin') this.$router.push('/');
      } else {
        this.noLogin = true;
        this.canSendLoginRequest = true;
        this.signOut();
        this.$router.push('/signin');
      }
    },
    signOut: function() {
      localStorage.clear();
      this.user = null;
      this.token = null;
      this.user_id = null;
      this.noLogin = true;
      this.canSendLoginRequest = true;
      this.showAuthScreen = false;
      this.outputLanguageId = null;
      this.enteredPhone = '';
      this.email = null;
      this.auth = null;
      this.currentSlide = 0;
      WebSocketService.disconnect();
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
