<template>
    <div class="p-4 lg:p-8">
      <h2 class=""><img class="h-8 w-auto mx-auto" src="/oasis.png" alt="OASIS"></h2>
      <h1 class="mt-2 text-2xl font-bold text-center">Interactive Transcript Editor</h1>
      <div class="relative mt-3 max-w-3xl mx-auto">
        <input 
          type="text" 
          v-model="enteredUser"
          @input="searchUser"
          placeholder="Search a user by name or email..." 
          class="block w-full text-base rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
          />
        <ul v-if="searchResults" class="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg">
            <li @click="$router.push('/users/443')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Matt Mireles</li>
            <li @click="$router.push('/users/443')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">John Smith</li>
            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Yousif Astarabadi</li>
            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Seena Zandipour</li>
        </ul>
      </div>
        
        <p class="text-center text-gray-500 mt-3">Or:</p>

    <input
        id="file-upload"
        name="file-upload"
        class="sr-only"
        type="file"
        ref="audioInput"
        @change="handleAudioChange"
        accept="audio/*"
    />
     <label for="file-upload" class="relative cursor-pointer rounded-md bg-white focus-within:outline-none">
        <div class="text-center pink-bg text-white max-w-3xl mx-auto border p-4 border-dashed border-white cursor-pointer my-3">
            <i class="fa fa-file mr-1"></i> Upload a new audio file & generate a transcript
        </div>
    </label>
      <p class="text-center text-gray-500">Or choose a past transcript:</p>
      <div v-if="transcripts" class="">
        <div v-for="transcript in transcripts" :key="transcript.id" @click="editTranscript(transcript)" class="relative max-w-3xl mx-auto border p-3 cursor-pointer my-3">
            <i @click.stop="deleteTranscript(transcript.id)" class="fa fa-trash absolute right-3 text-gray-300 hover:opacity-70 text-sm cursor-pointer"></i>
            <div v-if="!editing || (editing && editing.id !== transcript.id)">
                <b>{{ transcript.title }}</b>
                <i @click="editTitle(transcript)" class="fa fa-pencil text-gray-300 hover:opacity-70 text-xs ml-2"></i>
            </div>
            <div v-else>
                <input type="text" v-model="enteredTitle" />
                <i @click="saveTitle" class="fa fa-check text-gray-300 hover:opacity-70 text-sm ml-2"></i>
            </div>
            <div class="text-gray-500 text-sm">Updated: {{  generateTimestamp(transcript.update_ts) }}</div>
            <div class="text-gray-500 text-sm">Created: {{  generateTimestamp(transcript.create_ts) }}</div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import WebSocketService from '../services/WebSocketService';

export default {
    name: 'HomeView',
    props: {
        token: String
    },
    data() {
        return {
            transcripts: null,
            editing: null,
            enteredTitle: "",
            enteredUser: "",
            searchTimeout: null, // Add this to your data properties
            searchResults: null,
        }
    },
    mounted() {
        if(this.token) {
            WebSocketService.socketCheckIfReadyThenCallback(() => {
                WebSocketService.sendMessage('getUser');
            });

            this.fetchTranscripts();
        }
        else if(!this.token || this.token == null) {
            this.signOut();
        }
    },
    methods: {
        searchUser() {
            // Clear the previous timeout if there is one
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            if(this.enteredUser && this.enteredUser.trim() !== '') {
                // Set a new timeout
                this.searchTimeout = setTimeout(() => {
                    console.log("Searching for ", this.enteredUser);
                    fetch('https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/?query=' + this.enteredUser.trim(), {
                        method: "GET",
                        headers: {
                            Authorization: `Token ${this.token}`,
                        },
                        mode: "cors",
                        credentials: "omit"
                    })
                    .then(response => response.json())
                    .then(data => {
                        this.searchResults = [''];
                        console.log("Transcripts List = ", data.results);
                        this.transcripts = data.results.filter(i => !i.deleted);
                    });
                }, 500); // Adjust the delay as needed
            } else {
                this.searchResults = null;
            }
        },
        handleAudioChange() {
            //this.$emit("filePicked", file);
            this.$store.dispatch('updateAudioSrc', null);
            this.$store.dispatch('updateTranscript', null);
            this.$store.dispatch('updateFileObject', this.$refs.audioInput.files[0]);
            this.$router.push({
                name: 'Edit',
            });
        },
        editTranscript(transcript) {
            console.log("Editing...", transcript);
            this.$router.push("/edit/" + transcript.id);
        },
        generateTimestamp(title) {
            title  = new Date(title).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true , timeZone: this.timezone});
            return title;
        },
        signOut: function() {
            this.$emit("signOut");
            this.$router.push('/');
        },
        editTitle(transcript) {
            this.editing = transcript;
            this.enteredTitle = transcript.title;
        },
        fetchTranscripts() {
            fetch('https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/?show_transcripts=false', {
                method: "GET",
                headers: {
                    Authorization: `Token ${this.token}`,
                },
                mode: "cors",
                credentials: "omit"
            })
            .then(response => response.json())
            .then(data => {
                console.log("Transcripts List = ", data.results);
                this.transcripts = data.results.filter(i => !i.deleted);
            });
        },
        async deleteTranscript(id) {
            let confirmed = window.confirm("Are you sure you want to delete this transcript?");
            if(confirmed) {
                fetch('https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/' + id, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Token ${this.token}`,
                    },
                    mode: "cors",
                    credentials: "omit"
                })
                //.then(response => response.json())
                .then(data => {
                    // TODO: Add pagination
                    console.log("Transcript deleted! ", data);
                    this.transcripts = this.transcripts.filter(i => i.id !== id);
                });
            }
        },
        async saveTitle() {
            fetch('https://engdev.theoasis.com/api/v1/labs/transcript_verification_input/' + this.editing.id, {
                method: "PATCH",
                headers: {
                    Authorization: `Token ${this.token}`,
                    'Content-Type': 'application/json'
                },
                mode: "cors",
                credentials: "omit",
                body: JSON.stringify({ title: this.enteredTitle })
            })
            .then(response => response.json())
            .then(data => {
                console.log("Transcript title updated! ", data);
                let transcript = this.transcripts.find(i => i.id === this.editing.id);
                if (transcript) {
                    transcript.title = this.enteredTitle;
                }
                this.editing = null;
            });
        }
    }
};
</script>
  
<style scoped>
/* Add your component-specific styles here */
</style>
  